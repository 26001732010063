/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import {
	AddingBeegruPointsForSharePropertyThunk,
	AddingBeegruPointsForShareServiceThunk,
} from "../../redux-magic/thunks";

/* Component Imports */

import {
	Alert,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Link,
	Snackbar,
	// DialogActions,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
// import BeegruButton from "../common-components/buttons/BeegruButton";
import { useRouter } from "next/router";

/* Icon Imports */

import { Close } from "@mui/icons-material";
import { ObjectId } from "bson";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "1rem ",
	"& .MuiDialog-paper": {
		borderRadius: "16px",
		overflow: "hidden",
		[theme.breakpoints.down("sm")]: {
			margin: "0rem",
			width: "100%",
		},
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 1rem 0.5rem 1rem",
	background: theme.palette.background.paper,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "2rem 1rem 1rem 1rem",
	background: theme.palette.background.paper,
	gap: "1rem",
}));

// const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "flex-end",
//   alignItems: "center",
//   width: "100%",
//   gap: "1rem",
//   padding: "0rem 1rem 1rem 0rem",
//   background: theme.palette.mode === "dark" ? "#212B36" : "#FFFFFF",
// }));

const SocialButtonsConatiner = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "flex-start",
	flexWrap: "wrap",
	width: "100%",
	gap: "1rem",
	padding: "0.5rem",
	// margin: "0.25rem 0rem 0rem 0rem",
	[theme.breakpoints.down("sm")]: {
		padding: "0rem",
	},
}));

const SocialPaper = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	height: "5.5rem",
	width: "5.5rem",
	gap: "0.5rem",
	// margin: "0.5rem 0rem 0.5rem 0rem",
	borderRadius: "10px",
	cursor: "pointer",
	// border: "1px solid #E0E0E0",
	// background: theme.palette.mode == "dark" ? "#212B36" : "#FFFFFF",
	// background: theme.palette.mode === "dark" ? "#919EAB14" : "#fafafa",
	// boxShadow:
	//   theme.palette.mode == "dark"
	//     ? ""
	//     : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	"@media (pointer:fine)": {
		"&:hover": {
			border: "1px solid #E0E0E0",
			scale: "1.1",
			// background: theme.palette.mode === "dark" ? "#919EAB14" : "#ffffff",
		},
	},
	[theme.breakpoints.down("sm")]: {
		height: "3rem !important",
		width: "3rem !important",
	},
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2.5rem",
	width: "2.5rem",
	"&:hover": {
		// scale: "1.1",
	},
	[theme.breakpoints.down("sm")]: {
		height: "2rem",
		width: "2rem",
	},
}));

const CopyRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	justifyContent: "center",
	// marginTop: "1.5rem",
	marginBottom: "0rem",
	width: "100%",
	[theme.breakpoints.down("sm")]: {
		width: "100% !important",
		padding: "0rem",
	},
}));

const CopyInput = styled(TextField)(({ theme }) => ({
	width: "100%",
	margin: 0,
	height: "3.5rem",
	borderRadius: "5px 0px 0px 5px",
	[theme.breakpoints.down("sm")]: {
		height: "3.5rem !important",
	},
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		bottom: "5rem",
		borderRadius: "0.5rem",
	},
}));

const Label = styled(Typography)(({ theme }) => ({}));

// const CopyButton = styled(Button)(({ theme }) => ({
//   margin: 0,
//   padding: 0,
//   borderRadius: "0px 5px 5px 0px",
//   height: "2.5rem",
//   [theme.breakpoints.down("sm")]: {
//     height: "2.5rem !important",
//   },
//   color: "#FFFFFF",
//   background: "linear-gradient(138.59deg, #EE4B2B -15.15%, #FF8C3E 96.22%)",
// }));

const ShareModal = ({
	open,
	handleClose,
	url,
	dispatch,
	session,
	entity,
	creator_user_id,
}: {
	open: boolean;
	handleClose: any;
	url: string;
	dispatch: Function;
	session: any;
	entity?: string;
	creator_user_id?: string | ObjectId;
}) => {
	const theme = useTheme();
	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const handleclickCopy = (url: string) => {
		navigator.clipboard.writeText(url);
		setOpenSnackbar(true);
	};

	const handleCloseSnackbar = (event: any, reason: any) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackbar(false);
	};

	const router = React.useRef(useRouter()).current;

	const entityKannada: string =
		entity === "property"
			? "ಈ ಪ್ರಾಪರ್ಟಿಯನ್ನು"
			: entity === "post"
				? "ಈ ಪೋಸ್ಟ್"
				: entity === "Agent"
					? "ಈ ಏಜೆಂಟನ್ನು"
					: entity === "Developer"
						? "ಈ ಡೆವಲಪರ್ ಅನ್ನು"
						: entity === "Landowner"
							? "ಈ ಜಮೀನುದಾರನನ್ನು"
							: entity === "Professional"
								? "ಈ ಪ್ರೊಫೆಶನಲ್ ಪ್ರೊಫೈಲ್ ಅನ್ನು "
								: entity === "service"
									? "ಈ ಸೇವೆಯನ್ನು"
									: entity === "User"
										? "ಈ ಯೂಸರ್ ಅನ್ನು"
										: "";

	const localLang = {
		shareThis:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? entityKannada + " ಹಂಚಿಕೊಳ್ಳಿ"
					: "Share this " + entity,
		share: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಹಂಚಿಕೊಳ್ಳಿ" : "Share",
		email: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಇ-ಮೆಲ್" : "Email",
		whatsapp: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವಾಟ್ಸಪ್" : "WhatsApp",
		copy: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ನಕಲಿಸಿ" : "Copy",
		copylink:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಅಥವಾ ಈ ಲಿಂಕನ್ನು ನಕಲಿಸಿ" : "or copy this link",
		linkCopied: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಲಿಂಕ್ ನಕಲಿಸಲಾಗಿದೆ" : "Link Copied",
	};

	const property: boolean = router.asPath.includes("properties");

	const service: boolean = router.asPath.includes("services");

	const handleShareClick = async () => {
		if (session.user.dbUser._id === creator_user_id) {
			if (property) {
				await dispatch(
					AddingBeegruPointsForSharePropertyThunk({
						user_id: session.user.dbUser._id,
						url: router.asPath ? router.asPath : "",
					}),
				);
			}

			await dispatch(
				AddingBeegruPointsForShareServiceThunk({
					user_id: session.user.dbUser._id,
					url: router.asPath ? router.asPath : "",
				}),
			);
		}
	};

	return (
		<React.Fragment>
			{/* Snackbar */}

			<Snack
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert
					onClose={(e) => {
						handleCloseSnackbar(e, "");
					}}
					severity="success"
				>
					{localLang.linkCopied}
				</Alert>
			</Snack>

			{/* Share Modal */}

			<CustomDialog
				onClose={handleClose}
				open={open}
				maxWidth="xs"
				fullWidth={true}
				disableScrollLock={true}
			>
				<CustomDialogTitle>
					{entity ? localLang.shareThis  : localLang.share}
					<IconButton
						aria-label="close"
						onClick={handleClose}
					>
						<Close />
					</IconButton>
				</CustomDialogTitle>

				<CustomDialogContent>
					<SocialButtonsConatiner>
						{/* Whatsapp */}

						<Link
							href={
								"whatsapp://send?text=Have a look at this property on Beegru " +
								encodeURIComponent(url) +
								"?source=whatsapp"
							}
							// href={
							// 	"https://wa.me/?text=Have a look at this property on Beegru " +
							// 	encodeURIComponent(url) +
							// 	"?source=whatsapp"
							// }
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
							aria-label="Share via Whatsapp"
							sx={{ textDecoration: "none", color: "inherit" }}
							onClick={() => {
								session !== null ? (property || service ? handleShareClick() : null) : null;
							}}
						>
							<SocialPaper>
								<ImgTag
									src={
										theme.palette.mode === "dark"
											? "/images/icons/social/whatsapp-white.svg"
											: "/images/icons/social/whatsapp-orange.svg"
									}
									alt="Whatsapp"
									loading="lazy"
									referrerPolicy="no-referrer"
								/>
								<Label variant="body2">{localLang.whatsapp}</Label>
							</SocialPaper>
						</Link>

						{/* Email */}

						<Link
							href={
								"mailto:?&subject=Have%20a%20look%20at%20this%20property%20on%20Beegru.&body=" +
								encodeURIComponent(url) +
								"?source=email"
							}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
							aria-label="Share via Email"
							onClick={() => {
								session !== null ? (property || service ? handleShareClick() : null) : null;
							}}
							sx={{ textDecoration: "none", color: "inherit" }}
						>
							<SocialPaper>
								<ImgTag
									src={
										theme.palette.mode === "dark"
											? "/images/icons/social/mail-white.svg"
											: "/images/icons/social/mail-orange.svg"
									}
									alt="Email"
									loading="lazy"
									referrerPolicy="no-referrer"
								/>
								<Label variant="body2">{localLang.email}</Label>
							</SocialPaper>
						</Link>

						{/* Copy */}

						<SocialPaper>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/copy-white.svg"
										: "/images/icons/social/copy-orange.svg"
								}
								alt="Copy"
								loading="lazy"
								referrerPolicy="no-referrer"
								onClick={(e: any) => {
									handleclickCopy(url + "?source=copy");
									session !== null ? (property || service ? handleShareClick() : null) : null;
								}}
							/>
							<Label variant="body2">{localLang.copy}</Label>
						</SocialPaper>
					</SocialButtonsConatiner>

					<CopyRow>
						<CopyInput
							id="copyText"
							label={localLang.copylink}
							value={url}
							variant="outlined"
							InputProps={{
								readOnly: true,
							}}
						/>
					</CopyRow>
				</CustomDialogContent>

				{/* <CustomDialogActions>
          <BeegruButton
            flavor="primary"
            variant="outlined"
            onClick={handleClose}
            name="cancel"
            type="button"
          >
            Close
          </BeegruButton>
        </CustomDialogActions> */}
			</CustomDialog>
		</React.Fragment>
	);
};

export default ShareModal;
